import React from "react";

export default function Player() {
  // iframes
    return (
        <iframe
          className="video responsive"
          src="https://www.sports-stream.cc/ch/ch35.php"
          name="iframe_a"
          allowFullScreen
          width="60%"
          height="500px"
          title="Video Stream"
        >
          Your browser does not support iframes.
        </iframe>
      );
}


