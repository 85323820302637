import './App.css';
import React from 'react';
import HomePage from './pages/Home';
import VideoPage from './pages/Video';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavBar from './Header/NavBar';
import Player from './pages/Player';
import DownloadPage from './pages/DownloadPage';
import TelegramPage from './pages/TelegramPage';
import TvPage from './pages/TvsPage';




function App() {
  
  return (
    <div className='app-container'>
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/video' element={<VideoPage />} />
          <Route path='/player' element={<Player />} />
          <Route path='/download' element={<DownloadPage />} />
          <Route path='/telegram' element={<TelegramPage />} />
          <Route path='/tv' element={<TvPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
    
}

export default App;


