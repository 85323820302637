import React, {useEffect, useState} from 'react'

export default function Download({downloadOrder}) {

  // downloadHandler apk from the server
    const colors = ['#FF5733', 'green', '#3357FF', '#F333FF'];

    const downloadHandler = () => {
        const url = "https://live.football-live-tv.com/download";
        const url2 = "https://live.football-live-tv.com/yacine.apk";
         
        try {
            window.open(url2, '_blank');
         
        }
    catch (error) {
        fetch(url)
        .then(response => {
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = 'yacine.apk';
                a.click();
            });
        });  
    }
    }

const [color, setColor] = useState(colors[0]);


  useEffect(() => {
    if(downloadOrder) {
      downloadHandler();
    }
  
   
    const changeColor = () => {
      setColor((prevColor) => {
        const currentIndex = colors.indexOf(prevColor);
        const nextIndex = (currentIndex + 1) % colors.length;
        return colors[nextIndex];
      });
    };

    const intervalId = setInterval(changeColor, 1000);
    return () => clearInterval(intervalId);
  }, []); 



    return (
        <div>
            <button
             onClick={downloadHandler}
             className='download-button'
             style={{
                 padding: '10px 20px',
                 fontSize: '1.2rem',
                 backgroundColor: color,
                 color: 'white',
                 border: 'none',
                 borderRadius: '5px',
                 cursor: 'pointer',
                 fontWeight: 'bold'
                }}
             >Download Football App</button>
        </div>
    )
}
