import React from 'react'
import Download from '../downloads/Download';

export default function DownloadPage() {
    const downloadOrder = true
    return (
        <div style={
            {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '10%',
                marginBottom: '10%',
            }
        }>
           <Download downloadOrder={downloadOrder}/> 
        </div>
    )
}
