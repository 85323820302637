import React, {useEffect} from 'react'

export default function TvPage() {

    useEffect(() => {
        window.location.href = "https://t.me/footytvlive"
    }
    , [])
    return (
        <div>
            <h1>Telegram Page</h1>
        </div>
    )
}
