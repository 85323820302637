import React, { useEffect, useRef } from 'react';
import Hls from 'hls.js';

const HLSPlayer = ({ src, autoPlay = false, controls = true }) => {
    const videoRef = useRef(null);

    useEffect(() => {
        if (Hls.isSupported()) {
            const hls = new Hls();
            hls.loadSource(src);
            hls.attachMedia(videoRef.current);
        } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
            videoRef.current.src = src;
        }

        return () => {
            if (videoRef.current && videoRef.current.hls) {
                videoRef.current.hls.destroy();
            }
        };
    }, [src]);

    return (
        <video ref={videoRef} autoPlay={autoPlay} controls={controls} style={{ width: '100%' }} />
    );
};

export default HLSPlayer;